import Logo from "../imageassets/logo.png";
import fileorganise from "../imageassets/folders_organise.png";
import fileverify from "../imageassets/document.png";
import filedownload from "../imageassets/filedownload.png";
import coorporateworker from "../imageassets/worker.png";
import checkmark from "../imageassets/checked.png"
import checkmarkwhite from "../imageassets/checked white.png"
import earth_night from "../imageassets/earth_night.jpg"
import folder from "../imageassets/folderimag.svg"
import search from "../imageassets/search.svg"
import fileplacholder from "../imageassets/fileplaceholder.svg"
const assetprovider = {
  Logo: Logo,
  fileorganise: fileorganise,
  fileverify: fileverify,
  filedownload: filedownload,
  coorporateworker: coorporateworker,
  checkmark:checkmark,
  checkmarkwhite:checkmarkwhite,
  earth_night:earth_night,
  folder:folder,
  search:search,
  fileplacholder:fileplacholder
};

export default assetprovider;
