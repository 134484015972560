import assetprovider from "../contentsource/assetprovider";
function Aboutus(params) {
  return (
    <>
      <center
        className="aboutus"
        style={{
          backgroundImage: `url(${assetprovider.earth_night})`,
          backgroundSize: "fit",
        }}
      >
        <h2 className="" style={{ fontSize: "1.5rem" }}>
          Share the world with a click.<br></br>Discover stories , ideas , and
          <br></br>creativity from every corner of the globe. <br></br>File
          sharing brings us closer together,<br></br> one upload at a time.
        </h2>
      </center>
      <center className="maincontent" style={{ margin: "12px" }}>
        <center className="company" style={{ maxWidth: "600px" }}>
          <h4 style={{ fontSize: "1rem" }}>
            At FileHub, we aim to connect the world through shared content.
            Whatever your interests, and no matter where you live, we give you
            access to a vast array of files, documents, media, and software. Our
            users control what they want to share and download, whenever they
            want, with seamless and secure sharing. We support multiple
            languages and reach users in over 190 countries, because valuable
            content can originate from anywhere and be useful everywhere. We are
            passionate about sharing, and we’re always here to help you find the
            files you need.
          </h4>
        </center>
      </center>

      <center>
        <h2>THE STORY OF FILE BOX</h2>

        <div className="company">
          <p>
            The Brain child of XYZ,to solve problem of file sharing with
            dedication and effort we made file sharing as easy as messeging a
            friend.
          </p>
          <p>
            Story is yet to begin we have long way ahead, lets hope we suceed in
            our mission,To connect all.
          </p>
        </div>
      </center>
    </>
  );
}
export default Aboutus;
