import Header from "./componets/header.js";
import Landing from "./componets/landing.js";
import Login from "./componets/login.js";
import Footer from "./componets/footer.js";
import Signhup from "./componets/signup.js";
import { Route, Routes } from "react-router-dom";
import Pricing from "./componets/pricing.js";
import Aboutus from "./componets/aboutus.js";
import Dashbord from "./componets/dashbord/dashbord.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./App.css";

function App() {
  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
        <Header />
        <Routes>
          <Route
            path="*"
            element={
              <center style={{ height: "70vh" }}>
                <h1>Page Not Fount</h1>
              </center>
            }
          />
          <Route path="/" element={<Landing />} />
          <Route
            path="/login"
            element={
              <>
                <Login />
                <Footer />
              </>
            }
          />
          <Route
            path="/signup"
            element={
              <>
                <Signhup />
                <Footer />
              </>
            }
          />
          <Route
            path="/pricing"
            element={
              <>
                <Pricing /> <Footer />
              </>
            }
          />

          <Route
            path="/aboutus"
            element={
              <>
                <Aboutus /> <Footer />
              </>
            }
          />
          <Route path="/dashbord" element={<Dashbord />} />
        </Routes>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
