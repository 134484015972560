// load form memory
import coreconstants from "./conastants";
import assetprovider from "./assetprovider";
class DataSource {
  constructor() {
    const memsource = new loadformmemory(coreconstants.memsource);
    // const networksource=loadformnet(coreconstants.netsource);
    this.source = memsource;
  }
  getlandingcontent() {
    return this.source.getlandingcontent();
  }
  getpricingcontent() {
    return this.source.getpricingcontent();
  }
  getdasboarddata() {
    return this.source.getdasboarddata();
  }
}
class loadformmemory {
  constructor(data) {
    this.data = data;
  }
  getlandingcontent() {
    this.data["landingpage"].forEach((element) => {
      // console.log(element["img"]);
      if (assetprovider[element["img"]]) {
        element["img"] = assetprovider[element["img"]];
      }
    });
    return this.data["landingpage"];
  }
  getpricingcontent() {
    return this.data["pricing"];
  }
  getdasboarddata() {
    const result = [];

    this.data.folder.files.forEach((file) => {
      result.push({ name: file, type: "file" });
    });

    this.data.folder.folder.forEach((folder) => {
      result.push({ name: folder, type: "folder" });
    });
    return result;
  }
}

const datasource = new DataSource();
export default datasource;
