import assetprovider from "../../contentsource/assetprovider";
import contentprovider from "../../providers/contentprovider";
import React, { useState } from "react";
function sidebarelements({name}) {
  return (
    <div style={{ margin: "4%", width: "100%" }}>
      <input
        className="foldercheckbox"
        type="radio"
        name="main"
        id={name}
        value={name}
        hidden={true}
      />
      <label className="planslabel" htmlFor={name}>
        <div className="folder">
          <img src={assetprovider.folder} height={30} alt="" />
          <p>{name}</p>
        </div>
      </label>
    </div>
  );
}

function Folder({ name }) {
  return (
    <div style={{ margin: "4%", width: "100%" }}>
      <input
        className="foldercheckbox"
        type="radio"
        name="main"
        id={name}
        value={name}
        hidden={true}
      />
      <label className="planslabel" htmlFor={name}>
        <div className="folder">
          <img src={assetprovider.folder} height={30} alt=""/>
          <p>{name}</p>
        </div>
      </label>
    </div>
  );
}
function File({ name }) {
  return (
    <div style={{ margin: "3%", width: "100%" }}>
      <input
        className="foldercheckbox"
        type="radio"
        name="main"
        id={name}
        value={name}
        hidden={true}
      />
      <label className="planslabel" htmlFor={name}>
        <div className="folder">
          {/* <img src={assetprovider.folder} height={30} /> */}
          <p>{name}</p>
        </div>
      </label>
    </div>
  );
}

function Items({ name, view, lastedit, id, type }) {
  return (
    <React.Fragment key={id}>
      <input
        type="radio"
        name="centre"
        id={id}
        className="fileviewselectinp"
        hidden
      ></input>
      <label htmlFor={id} className="fileviewselectlabel planslabel">
        <div
          className="viewitem"
          style={{
            border: "1px solid",
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex" }}>
            <img
              src={
                type === "file"
                  ? assetprovider.folder
                  : assetprovider.fileplacholder
              }
              height="40px"
              style={{ padding: "7px" }}
              alt=""
            ></img>
            <p>{name}</p>
          </div>
          <div>
            <p>{view}</p>
          </div>
          <div>
            <p>{lastedit}</p>
          </div>
        </div>
      </label>
    </React.Fragment>
  );
}

function Dashbord(params) {
  const data = contentprovider.getdasboarddata();
  console.log(data);
  const [viewselect, setviewselect] = useState(null);
  const handler = (idx) => {
    setviewselect(idx);
  };

  return (
    <div className="dashbord">
      <div className="sidebar" style={{ width: "20vw", minWidth: "130px" }}>
        <form>
          <Folder name="hello" />
          <Folder name="world" />
          <Folder name="filebox" />
          <File name="file.txt" />
        </form>
      </div>
      <div className="fileview">
        <center>
          <p>
            <span>Welcome to Filebox</span>
          </p>
          <div style={{ padding: "2px" }}>
            <form>
              <div className="serchbox">
                <button
                  type="submit"
                  style={{
                    border: "unset",
                    cursor: "pointer",
                    borderRadius: "50%",
                    margin: "0px",
                    padding: "10px",
                  }}
                >
                  <img src={assetprovider.search} height={"100%"} alt=""/>
                </button>
                <input
                  type="text"
                  id="search"
                  placeholder="Search in Filebox"
                  className="signupinput"
                  spellCheck={false}
                  autoComplete="off"
                  style={{ border: "unset" }}
                />
              </div>
              <div style={{ height: "20px" }}></div>
            </form>
          </div>
        </center>
        <div style={{ height: "70vh" }}>
          {data.map((d, idx) => (
            <div>
              <Items
                name={d.name}
                lastedit={"20-01-2024"}
                view={"public"}
                id={idx}
                type={d.type}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dashbord;

//
