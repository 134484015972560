import datasource from "../contentsource/datasource";
class Contentprovider {
  constructor(source) {
    if (Contentprovider._instance) {
      return Contentprovider._instance;
      //   throw new Error(" Contentprovider Single class initialization error");
    }
    this.source = source;
  }
  getlandingcontent() {
    return this.source.getlandingcontent();
  }
  getpricingcontent(){
    return this.source.getpricingcontent()
  }
  getdasboarddata(){
    return this.source.getdasboarddata()
  }
}

const contentprovider = new Contentprovider(datasource);
export default contentprovider;
