function Login(params) {
  return (
    <div className="maincontent">
      <form className="company form">
        <div className="inputgroup">
          <label htmlFor="usermail" className="labels">
            Email
          </label>
          <input
            className="signupinput"
            type="email"
            name="usermail"
            id="usermail"
            placeholder="example@email.com"
            required
          />
        </div>
        <div className="inputgroup">
          <label htmlFor="password" className="labels">
            Password
          </label>
          <input
            className="signupinput"
            type="password"
            name="password"
            id="password"
            placeholder=""
            required
          />
        </div>
        <div className="inputgroup">
          <button className="signupbtn"> Login </button>
        </div>
        <center>
          <p>or continue with</p>
        </center>
        <div className="inputgroup">
          <button className="signupbtn"> Google </button>
          <div style={{ width: "20%" }}></div>
          <button className="signupbtn"> Linkedin </button>
          <div style={{ width: "20%" }}></div>
          <button className="signupbtn"> Facebook </button>
        </div>
      </form>
    </div>
  );
}

export default Login;
