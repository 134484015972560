import React, { useState } from "react";
import assetprovider from "../contentsource/assetprovider";
import contentprovider from "../providers/contentprovider";

function Pricebox({
  id,
  planname,
  price,
  storage,
  speed,
  filecount,
  onChangehandler,
  checked = false,
}) {
  return (
    <React.Fragment key={id}>
      <input
        className="plansinput"
        type="Radio"
        name="pln"
        value={id}
        id={id}
        onChange={onChangehandler}
        checked={checked}
      />
      <div className="plan">
        <label className="planslabel" htmlFor={id}>
          <div>
            <div className="planname">
              <h3> {planname} </h3>
            </div>
            <div className="checkiamgecontainer">
              <img
                className="checkedimg"
                src={assetprovider.checkmarkwhite}
                alt=""
              />
            </div>
          </div>
          <hr></hr>
          <p>Monthly Price</p>
          <p>{price}</p>
          <hr></hr>
          <p>storage size</p>
          <p>{storage}</p>
          <hr></hr>
          <p> Download speed</p>
          <p> {speed}</p>
          <hr></hr>
          <p>upload speed</p>
          <p>unlimited</p>
          <hr></hr>
          <p> files count</p>
          <p> {filecount} </p>
        </label>
      </div>
    </React.Fragment>
  );
}
function Pricing(params) {
  const [selectedPlan, setplan] = useState("1");
  const handleChange = (event) => {
    setplan(event.target.value);
  };
  const data = contentprovider.getpricingcontent();
  return (
    <center>
      <h2>Choose Best plan for your needs</h2>
      <form>
        <div className="plans">
          {data.map((d) => (
            <Pricebox
              key={d.id}
              id={d.id}
              planname={d.planname}
              price={d.price}
              storage={d.storage}
              speed={d.speed}
              filecount={d.filecount}
              checked={d.id === selectedPlan}
              onChangehandler={handleChange}
            />
          ))}
        </div>
        <br></br>
        <div className="btnholder">
          <button className="signupbtn"> Next </button>
        </div>
      </form>
    </center>
  );
}

export default Pricing;
