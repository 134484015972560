function Footer(params) {
    return(
        <footer className="footerclass">
            <div className="medialinks">
                <a href="http://twitter.com">
                    <p>twitter</p>
                </a>
                <a href="http://facebook.com">
                    <p>facebook</p>
                </a>
                <a href="http://github.com">
                    <p>github</p>
                </a>
                <a href="http://youtube.com">
                    <p>youtube</p>
                </a>
            </div>
            <hr></hr>
            <div className="copyright">
                <p>
                © Copyright All Rights Reserved 
                </p>
                <p >
                    developed by <a href="https://www.linkedin.com/in/lohith-n-9ba565221/" className="links"> @Loke </a>
                </p>
            </div>
        </footer>
    )
}
export default Footer;