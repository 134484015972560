import { useGoogleLogin } from "@react-oauth/google";
function Signhup(params) {
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => console.log(codeResponse),
    flow: "auth-code",
  });
  return (
    <div>
      <div className="maincontent">
        <form className="company form">
          <div className="inputgroup">
            <label htmlFor="usermail" className="labels">
              Email
            </label>
            <input
              className="signupinput"
              type="email"
              name="usermail"
              id="usermail"
              placeholder="example@email.com"
              required
            />
          </div>
          <div className="inputgroup">
            <label htmlFor="password" className="labels">
              Password
            </label>
            <input
              className="signupinput"
              type="password"
              name="password"
              id="password"
              placeholder=""
              required
            />
          </div>
          <div className="inputgroup">
            <label htmlFor="name" className="labels">
              Name
            </label>
            <input
              className="signupinput"
              type="text"
              name="name"
              id="name"
              placeholder="john"
              autoComplete="name"
              required
            />
          </div>
          <div className="inputgroup">
            {/* <button className="signupbtn"> login </button> */}
            <button className="signupbtn"> signup </button>
          </div>
          <center>
            <p>or continue with</p>
          </center>
          <div className="inputgroup">
            <button className="signupbtn" onClick={() => login()}>Google</button>
            <div style={{ width: "5%" }}></div>
            <button className="signupbtn">Linkedin</button>
            <div style={{ width: "5%" }}></div>
            <button className="signupbtn">Facebook</button>
          </div>
        </form>
      </div>
      {/* <div className="content"></div> */}
    </div>
  );
}

export default Signhup;
