import assetprovider from "../contentsource/assetprovider";
import { Link } from "react-router-dom";
function Header(params) {
  return (
    <div className="nav-bar">
      <div
        className=""
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <div className="nav-icon">
          <Link to="/">
            <img src={assetprovider.Logo} alt="logo" className="logo" />
          </Link>
        </div>
        <div className="nav-logo">
          <Link to="/" className="links">
            <h1>FILE BOX</h1>
          </Link>
        </div>
      </div>
      <input type="checkbox" name="nav" id="hamber" className="navinp" hidden/>
      <label htmlFor="hamber" className="toggle">
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </label>
      <ul className="navgroup">
        <Link to="/pricing" className="links">
          <li className="navitems">Pricing </li>
        </Link>
        <Link to="/aboutus" className="links">
          <li className="navitems">About us</li>
        </Link>
        <Link to="/dashbord" className="links">
          <li className="navitems">Help</li>
        </Link>
        <Link to="/signup" className="links">
          <li className="navitems">Signup</li>
        </Link>
        <Link to="/login" className="links">
          <li className="navitems">Login</li>
        </Link>
      </ul>
    </div>
  );
}

export default Header;
