import contentprovider from "../providers/contentprovider";

function landingmaincontent(params) {
  return (
    <div className="maincontent" key={params.id}>
      {params.id % 2 === 0 ? (
        <>
          <img src={params.img} alt="" className="sticker" />
          <div className="company">
            <h2>{params.h1}</h2>
            <p>{params.p}</p>
          </div>
        </>
      ) : (
        <>
          <div className="company">
            <h2>{params.h1}</h2>
            <p>{params.p}</p>
          </div>
          <img src={params.img} alt="" className="sticker" />
        </>
      )}
    </div>
  );
}

function Landing(params) {
  const data = contentprovider.getlandingcontent();
  return (
    <>
        <div>
          <div className="landingcompamny">
            <h1>Upload files to Filebox now</h1>
          </div>
          <div className="landingcompamny">
            <div className="fileupload">
              <input type="file" />
            </div>
          </div>
        </div>
      {data.map(function (d) {
        return landingmaincontent(d);
      })}
      {/* <div className="content"></div> */}
    </>
  );
}

export default Landing;
